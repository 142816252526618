import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './Subscribe.module.sass';
import ArticleTitle from '../shared/texts/ArticleTitle/ArticleTitle';
import Input from '../shared/Input/Input';
import ButtonSubmit from '../shared/buttons/ButtonSubmit/ButtonSubmit';
import { Formik } from 'formik';
import Recaptcha from 'react-google-invisible-recaptcha';


const SiteClient = require('datocms-client').SiteClient;

const client = new SiteClient(`${process.env.API_DATOCMS_KEY}`);


class Subscribe extends Component {
  state = {
    initialValues: {
      email: '',
    },
    afterMessage: false,
  };

  saveAdress = () => {
    const { initialValues: { email } } = this.state;
    client.items.create({
      email: email,
      itemType: '236229',
    })
      .then((item) => {
        this.setState({
          afterMessage: true,
        });
        return item;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { isActive } = this.props;
    const { initialValues, afterMessage } = this.state;
    return (
      <section className={cs(styles.subscribeWrap, { [styles.isActive]: isActive })}>
        <ArticleTitle className={styles.title}>Subskrybuj</ArticleTitle>
        <p className={styles.subtitle}>Zapisz się aby być zawsze na bieżąco</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            this.recaptcha.execute();
            this.setState({
              initialValues: {
                email: values.email,
              },
            });
            this.saveAdress();
            setSubmitting(false);
          }}
        >
          {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <Input
                name="email"
                className={styles.input}
                placeholder="E-mail"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />
              {/*//<Recaptcha*/}
             {/*//   ref={ ref => this.recaptcha = ref }*/}
             {/*//   sitekey={process.env.RECAPTCHA_KEY}*/}
             {/*//     onResolved={ () => console.log( 'Human detected.' ) } />*/}
              <p className={cs(styles.afterSubscribe, { [styles.isActive]: afterMessage })}>Dziękujemy za
                subskrypcje!</p>
              <ButtonSubmit type="submit" disabled={isSubmitting}>Zapisz się</ButtonSubmit>
            </form>
          )}
        </Formik>
      </section>
    );
  }
}

Subscribe.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Subscribe;
