import React from 'react';
import { Link } from 'gatsby';
import styles from './Footer.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = ({toggleSubscription}) => (
  <footer className={styles.footer} id="kontakt">
    <ul className={styles.socialMedia}>
      <li className={styles.socialItem}>
        <a className={styles.socialLink}  href="https://www.instagram.com/aboutdogs_pl/?hl=pl" target="_blank">
          <FontAwesomeIcon icon={faInstagram}/>
        </a>
      </li>
      <li className={styles.socialItem}>
        <a className={styles.socialLink}  href="https://www.facebook.com/AboutDogs-102789954830978" target="_blank">
          <FontAwesomeIcon icon={faFacebookF}/>
        </a>
      </li>
    </ul>
    <div className={styles.menu}>
      <ul className={styles.footerMenu}>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/o-mnie">o mnie</Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/blog">Blog</Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.menuLink} to="/podcasty">Podcasty</Link>
        </li>
      </ul>
      <button className={styles.subscribe} onClick={toggleSubscription}>Subskrybuj</button>
    </div>
    <div className={styles.contact}>
      <p className={styles.title}>Kontakt</p>
      <a className={styles.contactLink} href="tel: 507822267">507 822 267</a>
      <a className={styles.contactLink} href="mail: kasia@aboutdogs.pl">kasia@aboutdogs.pl</a>
    </div>
  </footer>
);

export default Footer;