import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from './ArticleTitle.module.sass';

const ArticleTitle = ({ children, className, ...props }) => (
  <h2 {...props} className={cs(styled.articleTitle, className)}>{children}</h2>
);

ArticleTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ArticleTitle.defaultProps = {
  className: ''
}

export default ArticleTitle;