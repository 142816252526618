import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cs from 'classnames';
import styles from './NavBar.module.sass';
import logo from 'src/assets/img/logoColor.svg';
import Burger from '../Burger/Burger';

class NavBar extends Component {
  state = {
    navBarSticky: false,
    scrollDown: false,
    mobileNavBar: false,
  };

  hideNavBar = () => {
    this.setState({
      navBarSticky: false,
    });
  };

  scrollDown = (scroll) => {
    if (scroll > 100) {
      this.setState({
        scrollDown: true,
      });
    }
  };

  scrollUp = (scroll) => {
    this.setState({
      navBarSticky: true,
    });

    if (scroll < 200) {
      this.setState({
        navBarSticky: false,
        scrollDown: false,
      });
    }
  };

  toggleMobileNavBar = () => {
    this.setState(prevState => ({
      mobileNavBar: !prevState.mobileNavBar,
    }));
  };

  componentDidMount() {
    let prevScroll = window.scrollY;
    let scroll;
    document.addEventListener('scroll', () => {
      scroll = window.scrollY;
      if (prevScroll > scroll) {
        this.scrollUp(scroll);
      } else if (prevScroll < scroll) {
        this.scrollDown(scroll);
        this.hideNavBar();
      }
      prevScroll = scroll;
    });
  }

  render() {
    const { navWhite } = this.props;
    const { navBarSticky, mobileNavBar, scrollDown } = this.state;
    return (
      <nav
        className={cs(styles.navBar, 'wrap', { [styles.active]: navBarSticky }, { [styles.disable]: scrollDown }, { [styles.whiteBar]: navWhite })}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="About Dogs Logo"/>
        </Link>
        <ul className={cs(styles.menu, { [styles.active]: mobileNavBar })}>
          <li className={styles.menuItem}><Link className={styles.menuLink} activeClassName={styles.active}
                                                to="/o-mnie">o mnie</Link></li>
          <li className={styles.menuItem}><Link className={styles.menuLink} activeClassName={styles.active}
                                                to="/podcasty">podcasty</Link></li>
          <li className={styles.menuItem}><Link className={styles.menuLink} activeClassName={styles.active}
                                                to="/blog">blog</Link></li>
          <li className={styles.menuItem}><Link className={styles.menuLink} activeClassName={styles.active}
                                                to="/rasy-psow">rasy psów</Link></li>
          <li className={styles.menuItem}><Link className={styles.menuLink} activeClassName={styles.active}
                                                to="/#kontakt">kontakt</Link></li>
        </ul>
        <Burger active={mobileNavBar} onClick={this.toggleMobileNavBar}/>
      </nav>
    );
  }
}

NavBar.propTypes = {
  navWhite: PropTypes.bool,
};

NavBar.defaultProps = {
  navWhite: false,
};

export default NavBar;